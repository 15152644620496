import React from 'react'
import {graphql} from 'gatsby'
import posed from 'react-pose'
import {CUSTOMERS} from '../constants'
import styled from 'styled-components'
import {useIsMobile} from '../uses/layout.uses'
import Page from '../components/Layout/Page'

const Partners = ({...p}) => {
    const isMobile = useIsMobile()

    return (
        <Page {...p}>
            <InnerContainer>
                {CUSTOMERS.map((customer) => (
                    <Customer target="_blank" href={customer.url} w={isMobile ? 50 : 30}>
                        <CustomerImage src={customer.logo} isMobile={isMobile} />
                    </Customer>
                ))}
            </InnerContainer>
        </Page>
    )
}

export default Partners

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`

const PosedInnerContainer = posed.ul({
    wide: {width: '100%'},
    narrow: {width: '100%'},
})

const InnerContainer = styled(PosedInnerContainer)`
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    padding-inline-start: 0;
`
const PosedCustomer = posed.a({
    hoverable: true,
    init: {scale: 0.7},
    hover: {scale: 1},
})

const Customer = styled(PosedCustomer)`
    width: ${(p) => p.w}%;
`

const CustomerImage = styled.img`
    padding: 20px 10px;
    width: 100%;
`
